import einsteinImage from '../../assets/einstein_stuff/einstein_cover_img.jpg'
import './Service.css'




const Service = () => {

    return (
        <div className="service-container">
            <div className="service">
                <div className="service-top">
                    <h1>Our Services</h1>
                </div>
                <div className="service-bottom">
                    <div className="service_image_overlay">
                        <img id="computer" src={einsteinImage}></img>
                    </div>
                    <div>
                        <ul>
                            <li>Equity Plan Administration</li>
                            <li>Cap Table Reporting</li>
                            <li>Stock Based Compensation Expense</li>
                            <li>Tax Reporting</li>
                            <li>Shareworks Implementation Assistance</li>
                            <li>Participant Communication</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Service
