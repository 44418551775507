import { useState, useEffect } from 'react'
import communicationImg from '../assets/einstein_stuff/services/Communication.jpg'
import equityAdminImg from '../assets/einstein_stuff/services/Equity_Admin.jpg'
import financialRptImg from '../assets/einstein_stuff/services/Financial_Reporting.jpg'
import implementationImg from '../assets/einstein_stuff/services/Implementation.jpg'
import taxImg from '../assets/einstein_stuff/services/Tax.jpg'
import otherImg from '../assets/einstein_stuff/services/Other.jpg'
import tempimage from '../assets/einstein_stuff/fancy_graph.png'
import ServiceCard from './ServiceCard'
import ServiceDetailPage from './ServiceModal'
import ContactForm from '../ContactForm/ContactForm'
import './ServicePage.css'



const ServicesPage = () => {

    const [showDetails, setShowDetails] = useState(false)
    const [serviceName, setServiceName] = useState(null)


    const loadDetails = (e) => {
        const id = e.target.dataset.id
        setShowDetails(true)
        setServiceName(servicesList[id])
        window.scrollTo(0, 0)
    }

    useEffect(() => {

        const trackedElement = document.querySelector('[data-name="services-1]')
        const trackedElementClickHandler = () => {
            setShowDetails(false)
            setServiceName(null)
        }

        if (trackedElement) {
            trackedElement.addEventListener('click',
                trackedElementClickHandler
            )
        }

        if (showDetails) {
            window.scrollTo(0, 0)
        }
    }, [showDetails])

    const listItems = document.querySelectorAll('.clickable');

    listItems.forEach((item) => {
        item.addEventListener('click', () => {
            listItems.forEach((li) => {
                li.classList.remove('clicked');
            });
            item.classList.add('clicked');
        });
    });


    return (
        <div className="services_page_container">
            <div className="services_page_main">
                <div className="services_sidebar">
                    <li className="clickable" data-id="0" onClick={(e) => loadDetails(e)}>Equity Admin Services</li>
                    <li className="clickable" data-id="1" onClick={(e) => loadDetails(e)}>Financial Reporting</li>
                    <li className="clickable" data-id="2" onClick={(e) => loadDetails(e)}>Tax Reporting</li>
                    <li className="clickable" data-id="3" onClick={(e) => loadDetails(e)}>Shareworks Implementation</li>
                    <li className="clickable" data-id="4" onClick={(e) => loadDetails(e)}>Participant Communication</li>
                    <li className="clickable" data-id="5" onClick={(e) => loadDetails(e)}>Additional Services</li>
                </div>
                <div className="services_card_container">
                    {showDetails ? (
                        <ServiceDetailPage
                            service={serviceName}
                            setShowDetails={setShowDetails}
                            setServiceName={setServiceName}
                        />
                    ) : (
                        servicesList.map((ele) => (
                            <ServiceCard
                                key={ele.title}
                                service={ele}
                                bannerImage={serviceCardImg[ele.title]}
                                setShowDetails={setShowDetails}
                                setServiceName={setServiceName}
                            />
                        ))
                    )}
                </div>
            </div>
            <div className="finalcta-container">
                <div className="finalcta">
                    <h1>Any questions?</h1>
                    <h3>Reach out to us below</h3>
                    <ContactForm />
                </div>
            </div>
        </div>
    )
}

export default ServicesPage









const serviceCardImg = {
    "Equity Admin Services": equityAdminImg,
    "Financial Reporting": financialRptImg,
    "Tax Reporting": taxImg,
    "Shareworks Implementation": implementationImg,
    "Participant Communication": communicationImg,
    "Additional Services": otherImg
}



const equityAdminServicesObj = {
    id: 1,
    title: "Equity Admin Services",
    items: [
        {
            header: "Ongoing maintenance of a healthy equity plan can include (but not limited to) the following:",
            subItems: [
                "Updating database with new grants, issuing notice of grant and grant agreements to participants",
                "Supporting online 'Grant Acceptance'",
                "Grant agreement acceptance tracking",
                "Entering employee terminations and track post-termination exercise periods",
                "Providing termination statements for terminated employees",
                "Maintaining stock plan exercises",
                "Supporting the tax-withholding process in conjunction with the payroll department",
                "Tracking $100,000 Incentive Stock Option value, including conversion to Non-qualified stock options for any option in excess of the limit",
                "Cap Table development and maintenance, monthly cap table reporting & quarterly rollforward",
                "Option pool tracking against equity forecast",
                "701 Rule analysis when applicable"
            ]
        }
    ]
}


const financialReportingObj = {
    id: 2,
    title: "Financial Reporting",
    items: [
        {
            header: "Stock Based Compensation",
            subItems: [
                "Valuation of new grants in Shareworks: expected term, peer group volatility, forfeiture rate if applicable, and interest rate",
                "Analyze modifications etc. and execute both in the admin and expensing side",
                "Expense analysis for appropriateness and completeness including journal entries at monthly and/or quarterly and year-end",
                "Monthly reconciliation of equity related accounts (Common Stock, APIC and SBC Expense)",
                "Forecasting of future stock-based compensation expense",
                "Expense for option repricing and stock splits"
            ]
        },
        {
            header: "Audit & Financial Reporting",
            subItems: [
                "Audit support including preparation of PBCs and interface with auditors",
                "Preparation of equity related disclosures and financial statements"
            ]
        }
    ]
}


const taxReportingObj = {
    id: 3,
    title: "Tax Reporting",
    items: [
        {
            header: null,
            subItems: [
                "3921 reports for Incentive Stock Option exercises",
                "Annual reporting with the IRS",
                "Gain on the Non-Qualified exercise included in the W2 at year-end",
                "Prepare PBC’s for tax provision",
                "1099 reporting assistance through accounts payable at year-end"
            ]
        }
    ]
}

const shareWorksImplementationObj = {
    id: 4,
    title: "Shareworks Implementation",
    items: [
        {
            header: null,
            subItems: [
                "Launching Shareworks portal to participants",
                "Single Sign On access (SSO)",
                "Link HRIS system with Shareworks",
                "Online payments at exercise",
                "Implementation equity awards and expensing from Excel to Shareworks",
                "Implementation from other systems to Shareworks",
                "Cleanup Shareworks administration and expensing data"
            ]
        }
    ]
}

const participantCommunicationObj = {
    id: 5,
    title: "Participant Communication",
    items: [
        {
            header: null,
            subItems: [
                "Equity 101 specific to your company plan",
                "Company-specific email (stockadmin@yourcompany.com) to answer individual participant questions",
                "Option exercise assistance"
            ]
        }
    ]
}

const additionalServicesObj = {
    id: 6,
    title: "Additional Services",
    items: [
        {
            header: "We also provide additional, but not limited to, the following services:",
            subItems: [
                "Tender offer preparation and in-flight support during event: Analysis and reporting for tender offers and secondary market transactions",
                "Internal controls and SOX",
                "Option repricing",
                "Basic and diluted share reports to support EPS calculations",
                "IPO readiness and support during the IPO transition"
            ]
        }
    ]
}

const servicesList = [
    equityAdminServicesObj,
    financialReportingObj,
    taxReportingObj,
    shareWorksImplementationObj,
    participantCommunicationObj,
    additionalServicesObj
]
