import ContactForm from '../../ContactForm/ContactForm'
import './FinalCTA.css'



const FinalCTA = () => {


    return (
        <div className="finalcta-container">
            <div className="finalcta">
                <h1>Find out what Einstein Equity can do for you</h1>
                <h3>Free Consultation</h3>
                <ContactForm />
            </div>
        </div>
    )
}

export default FinalCTA
