import './Footer.css'



const Footer = () => {


    return (
        <div className="footer-container">
            <div className="footer">
                <div className="footer-top">
                    {/* <div className="columns">
                        <span>Features</span>
                        <ul>
                            <li>Mobile App</li>
                            <li>Integrations</li>
                            <li>Business Messaging</li>
                            <li>Rich Text Messaging</li>
                            <li>VOIP/Landling Texting</li>
                        </ul>
                    </div>
                    <div className="columns">
                        <span>Learn</span>
                        <ul>
                            <li>Book Demo</li>
                            <li>Webinar</li>
                            <li>Partner Program</li>
                        </ul>
                    </div>
                    <div className="columns">
                        <span>Resources</span>
                        <ul>
                            <li>About Us</li>
                            <li>Blog</li>
                            <li>Knowledge Base</li>
                            <li>Careers</li>
                            <li>Developer Portal</li>
                            <li>SMS Length Calculator</li>
                            <li>Case Studies</li>
                            <li>Contact Us</li>
                            <li>Site Map</li>
                        </ul>
                    </div>
                    <div className="columns">
                        <span>Legal</span>
                        <ul>
                            <li>Privacy Policy</li>
                            <li>Terms of Service</li>
                            <li>GDPR</li>
                        </ul>
                    </div> */}
                    <div className="columns">
                        <span>Connect with us</span>
                        <div className="social-links">
                            {/* <i className="fab fa-facebook"></i> */}
                            <a href="https://www.linkedin.com/company/einstein-equity/" target="_blank" rel="noreferrer" >
                                <i className="fab fa-linkedin"></i>
                            </a>
                            {/* <i className="fab fa-youtube"></i>
                            <i className="fab fa-twitter"></i> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
