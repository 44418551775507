import "./about.css"





const AboutIntro = () => {

    return(
        <div className="about-intro">
            <h1>About Us</h1>
            Equity doesn’t have to be complicated.  We provide simple equity administrative solutions to manage your equity plan.
            <br/>
            <br/>
            We bring many years of experience with hundreds of private companies as they grew in complexity from startup to liquidity tender offers to IPO.
            <br/>
            <br/>
            Making equity available to employees is key to exponential growth and establishing an owner mindset from everyone in the organization. Managing the equity program as it grows also means extra administrative time and effort to ensure it stays up-to-date which is where we can help!
        </div>
    )
}


export default AboutIntro
