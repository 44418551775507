import './ServiceDetails.css'





const ServiceDetailPage = ({ service, setShowDetails, setServiceName }) => {

    const handleBackClick = () => {
        setShowDetails(false)
        setServiceName(null)
        return
    }

    return (
        <div className="service_details">
          <h1>{service.title}</h1>
          <div>
            {service.items.map((item) =>
              item.header ? (
                <div key={item.header}>
                  <p className="service_details_header">{item.header}</p>
                  {item.subItems.map((subItem) => (
                    <div className="service_details_subitems" key={subItem}>{subItem}</div>
                  ))}
                </div>
              ) : (
                <div key={item.subItems.join(",")}>
                  {item.subItems.map((subItem) => (
                    <div className="service_details_subitems" key={subItem}>{subItem}</div>
                  ))}
                </div>
              )
            )}
          </div>
          <span onClick={handleBackClick}>Back</span>
        </div>
      );
    };

 export default ServiceDetailPage
