import './NavBar.css'
import { Link } from 'react-router-dom'
import einsteinLogo  from '../assets/einstein_stuff/oie_XpmF1bMLdXQB.png'

const NavBar = () => {


    return (
        <nav>
            <div className="navbar">
                <div className="navbar_img">
                    <Link to="" onClick={() => window.scrollTo(0, 0)}>
                        <img src={einsteinLogo} alt="Einstein_Logo" />
                        <span className="logo_title">Einstein Equity</span>
                    </Link>
                </div>
                <ul className="navbar_links">
                    <li>
                        <Link className="home-link" to="">
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link className="about-link" to="/about">
                            About
                        </Link>
                    </li>
                    <li>
                        <Link className="blog-link" to="/blog">
                            Blog
                        </Link>
                    </li>
                    <li>
                        <Link data-name="services-1" className="services-link" to="/services">
                            Services
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>
    )
}

export default NavBar
