import Hero from "./Hero/Hero"
import Service from "./Service/Service"
import Integrate from "./Integrate/Integrate"
import Testimonials from "./Testimonials/Testimonials"
import FinalCTA from "./FinalCTA/FinalCTA"
import WhoWeServe from "./WhoWeServe/WhoWeServe"


const LandingPage = () => {

    return (
        <>
            <Hero />
            {/* <WhoWeServe /> */}
            <Integrate />
            <Service />
            <Testimonials />
            <FinalCTA />
        </>
    )
}

export default LandingPage
