import { Link } from 'react-router-dom'
import BlogCard from './BlogCard'
import blogs from './blog/blog_manifest.json'
import './MainBlog.css'



const MainBlog = () => {

    let latestBlog;

    if (blogs && blogs.length > 0) {
        latestBlog = blogs[0]
    }

    return(
        <div className="MainBlog-Container">
            <div className="MainBlog-Top">
                <div className="Most_Recent_Blog_Container">
                    <Link to={`/blog/0/${latestBlog.date.replace(/\//g, '')}/${latestBlog.title}`} className="Most_Recent_Blog_Card">
                        <div className="Most_Recent_Blog_Card_Left">
                            <img src={require(`${latestBlog.src}`)}/>
                        </div>
                        <div className="Most_Recent_Blog_Card_Right">
                            <h1>{latestBlog.title}</h1>
                            <span>{latestBlog.date}</span>
                            <p>{latestBlog.summary}</p>
                            <span>read full post</span>
                        </div>
                    </Link>
                </div>
            </div>
            <div className="MainBlog-Bottom">
                {
                    blogs.map((blog, idx) => (
                        idx === 0 ? null :
                        <BlogCard idx={idx} blogDetails={blog} />
                    ))
                }
            </div>
        </div>
    )
}


export default MainBlog
