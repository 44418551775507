import miguelProfile from '../../assets/einstein_stuff/miguel_profile.jpg'
import mariloProfile from '../../assets/einstein_stuff/marilo_profile.png'

import './Integrate.css'



const Integrate = () => {


    return (
        <div className="integrate-container">
            <div className="integrate">
                <div className="integrate-top">
                    <h1>Meet the team</h1>
                </div>
                <div className="integrate-bottom">
                    <div className="miguel-intro">
                        <div className="miguel-img-container">
                            <img src={miguelProfile} alt="miguel_profile" />
                        </div>
                        <div className="miguel-intro-paragraph-container">
                            <h1>Miguel Chavez</h1>
                            <p>Miguel brings many years of equity management experience as a Vice President, Senior Shareworks Relationship Manager servicing the top private technology companies through IPO. As a Certified Equity Professional (CEP), Miguel brings both deep knowledge and hands on experience in practical application of equity to attract and retain talent. Miguel holds a B.A. in Psychology from Stanford University and an M.B.A. from the W.P. Carey School of Business. </p>
                        </div>
                    </div>
                    <div className="marilo-intro">
                        <div className="marilo-img-container">
                            <img src={mariloProfile} alt="marilo_profile" />
                        </div>
                        <div className="marilo-intro-paragraph-container">
                            <h1>Mariló Calabuig</h1>
                            <p>Mariló brings many years of equity compensation consultant, specializing in audit, financial reporting and special projects. Mariló has worked as an auditor both in the U.S. and in Spain where she is from and also hold several corporate roles in early stage to fortune 500 companies. As a Certified Equity Professional (CEP), and a Certified Public Accountant, CPA Inactive, Mariló brings deep accounting and administration experience. Mariló holds a B.A. in Accounting from the Catalonian International University (UIC) in Barcelona. </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Integrate
