import './ServiceCard.css'
import tempimage from '../assets/einstein_stuff/fancy_graph.png'
import { Link } from 'react-router-dom'



const ServiceCard = ({ service, setShowDetails, setServiceName,  bannerImage }) => {


    const serviceModalController = () => {
        setServiceName(service)
        setShowDetails(true)
        return
    }

    return(
        <Link onClick={serviceModalController} className="service_card_container">
            <div className="service_card_main">
                <div className="service_card_main_top">
                    <img src={bannerImage}/>
                </div>
                <div className="service_card_main_bottom">
                    <span>{service.title}</span>
                </div>
            </div>
        </Link>
    )
}

export default ServiceCard
