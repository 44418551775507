import WhoWeServe from "../Landing/WhoWeServe/WhoWeServe";
import AboutIntro from "../about/Intro"
import Footer from "../Landing/Footer/Footer";
import "./about.css"

const AboutUs = () => {

    return(
        <div className="about_us_container">
            <AboutIntro />
            <WhoWeServe />
        </div>
    )
}


export default AboutUs
