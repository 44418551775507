import "./Hero.css"
import { Link } from "react-router-dom"


const Hero = () => {

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
        });
      };


    return (
        <div className="hero-container">
            <div className="hero">
                <div className="hero-left">
                    <h1>Equity Management Made Simple</h1>
                    <p>We'll manage your equity plan</p>
                    <p>so you can focus on growing your business.</p>
                    <h3></h3>
                    <Link to={"/services"} onClick={scrollToTop}>Learn More</Link>
                </div>
            </div>
        </div>

    )
}

export default Hero
