import alex from '../../assets/einstein_stuff/alex_profile.jpg'
import cecilia from '../../assets/einstein_stuff/cecilia_profile.jpg'
import isaac from '../../assets/einstein_stuff/isaac_profile.jpg'
import lisa from '../../assets/einstein_stuff/lisa_profile.jpg'
import jose from '../../assets/einstein_stuff/jose_profile.jpg'
import mcafeeLogo from '../../assets/einstein_stuff/mcafeelogo.png'
import bpmLogo from '../../assets/einstein_stuff/bpm_logo.png'
import ltseLogo from '../../assets/einstein_stuff/ltse_logo.png'
import ideanomicsLogo from '../../assets/einstein_stuff/ideanomics_logo.png'
import earninLogo from '../../assets/einstein_stuff/earnin_logo.png'
import './Testimonials.css'



const Testimonials = () => {


    return (
        <div className="testimonials-container">
            <div className="testimonials">
                <div className="testimonials-top">
                    <h1>What people are saying</h1>
                </div>
                <div className="testimonials-bottom">
                    <div className="testimonials-card">
                        {/* <img className="testimonials-logo" src={mcafeeLogo}></img> */}
                        {/* <h3>Increased acquistion by 92% &nbsp;</h3> */}
                        <p>“I worked with Miguel Chavez for years and he always went above and beyond. His desire to ensure top notch customer service combined with his deep understanding of Shareworks made our private to public transition feel like a walk in the park.”</p>
                        <div>
                            <img className="testimonial-img" src={isaac}></img>
                            <div>
                                <span>Isaac Culbertson</span>
                                <span>Director of Finance and Accounting at McAfee</span>
                            </div>

                        </div>
                    </div>
                    <div className="testimonials-card">
                        {/* <img className="testimonials-logo" src={bpmLogo}></img> */}
                        {/* <h3>Improved Customer Engagement by 30% &nbsp;</h3> */}
                        <p>"It’s very fitting that Miguel is now “Einstein Equity” because he is an absolute genius in the equity space. Aside from having an amazing memory and being an encyclopedia about equity, he has a creative mind with extreme attention to detail. Another notable thing about Miguel is his calm demeanor which is never shaken during stressful or urgent situations. During my several years working at Shareworks, Miguel and I worked together closely on a very important account with exceedingly complex and sensitive needs. The solutions and accommodations Miguel was able to achieve on a regular basis was impressive and valuable, especially considering that his role was intended to be a relationship manager with only a basic level of involvement, yet he is easily in the top 1% of fluent and capable Shareworks super-users. In fact, even after departing Shareworks, Miguel is still compelled to read the ‘Release Notes’, keeping up on the latest Shareworks enhancements and changes. He has even reached out to me a couple of times excited to discuss certain breakthroughs! I can’t speak highly enough of Miguel. From equity law, regulations, taxation, and best practices all the way to complex reporting needs from Shareworks, you will absolutely be in great hands."</p>
                        <div>
                            <img className="testimonial-img" src={alex}></img>
                            <div>
                                <span>Alex Scothern</span>
                                <span>Manager of Stock Plan Administration at BPM</span>
                            </div>

                        </div>
                    </div>
                    <div className="testimonials-card">
                        {/* <img className="testimonials-logo" src={ltseLogo}></img> */}
                        {/* <h3>Saves 5 Hrs/Month on Data Cleanup &nbsp;</h3> */}
                        <p>"Marilo and I worked together on a very complex equity translation transaction. She was a trusted partner in navigating the myriad of issues to ensure that every aspect was addressed. Marilo’s equity experience was invaluable, and she has excellent attention to detail and the tenacity to keep working through difficult issues. Plus, she is an absolute joy to work with! I highly recommend her."</p>
                        <div>
                            <img className="testimonial-img" src={lisa}></img>
                            <div>
                                <span>Lisa Young</span>
                                <span>General Counsel at Long-Term Stock Exchange</span>
                            </div>
                        </div>
                    </div>
                    <div className="testimonials-card">
                        {/* <img className="testimonials-logo" src={ideanomicsLogo}></img> */}
                        {/* <h3>Increased acquistion by 92% &nbsp;</h3> */}
                        <p>“It was fantastic to work with Marilo. She has exceptional expertise in equity compensation. She provided integrated advice and consultation to our equity compensation covering new accounting guidance adoption, tax and regulation compliance, system implementation, option splits, forecasting, SOX controls etc. She owned our equity process and consistently demonstrated a solid work ethic and dedication to success. I hope we can work together again in the future.”</p>
                        <div>
                            <img className="testimonial-img" src={cecilia}></img>
                            <div>
                                <span>Cecilia Xu</span>
                                <span>CPA, Corporate Controller at Ideanomics</span>
                            </div>
                        </div>
                    </div>
                    <div className="testimonials-card">
                        {/* <img className="testimonials-logo" src={earninLogo}></img> */}
                        {/* <h3>Saves 5 Hrs/Month on Data Cleanup &nbsp;</h3> */}
                        <p>"Marilo is a pleasure to work with and she has an incomparable technical background in stock-based compensation. In my capacity as a Controller, I hired Marilo's organization and specifically requested her involvement on the team to assist with a multitude of stock-based compensation tasks ranging from run-of-the-mill expensing to complex transactions. Marilo performed such work flawlessly; as she performed the work, she went out of her way to highlight the relevant guidance to help explain the context, and her dedication and reliability to get the job done were superb. Marilo also has a keen eye for issues and recommends process improvements as she goes about performing her assignments. I would wholeheartedly recommend Marilo to assist with stock-based compensation matters, and I look forward to the opportunity and pleasure to work with her again."</p>
                        <div>
                            <img className="testimonial-img" src={jose}></img>
                            <div>
                                <span>Jose V. Peris</span>
                                <span>VP Controller at Earnin</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Testimonials
