import React from 'react';
import { Route, Routes } from "react-router-dom";
import LandingPage from "./components/Landing/LandingPage"
import MainBlog from './components/blog/MainBlog';
import NavBar from "./components/NavBar/NavBar";
import AboutUs from './components/about';
import ServicesPage from './components/services';
import Footer from './components/Landing/Footer/Footer';
import SingeBlogPage from './components/blog/SingleBlogPage';
import './index.css';

function App() {
  return (
    <div id="app">
      <NavBar />
      <main>
        <Routes>
          <Route path="" element={<LandingPage />} />
          <Route path='/blog' element={<MainBlog />} />
          <Route path='/about' element={<AboutUs />} />
          <Route path='/services' element={<ServicesPage />} />
          <Route path='/blog/:idx/:date/:title' element={<SingeBlogPage />} />
        </Routes >
      </main>
      <Footer />
    </div>
  );
}

export default App;
