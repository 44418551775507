import emailjs from '@emailjs/browser';
import { useRef, useState, useEffect } from 'react';
import './ContactForm.css';

const ContactForm = () => {


    const form = useRef();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [sentError, setSentError] = useState(false);

    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [messageError, setMessageError] = useState('');

    const [showError, setShowError] = useState(false);


    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };


    const checkFormErrors = (e) => {
        e.preventDefault()
        const submitButton = document.getElementById("submit_btn")

        if (nameError.length || emailError.length || messageError.length) {
            setShowError(true)
            return "contact form has errors"
        } else {
            setShowError(false)
            sendEmail()
        }
        return true
    }


    const removeForm = () => {
        const formContainer = document.querySelector('#contact-form_container');
        const form = document.querySelector('#contact-form');
        const tyMessage = document.createElement('p')

        tyMessage.innerHTML = "Thank you for your message. We will reach out to you soon.";

        if (form) {
            formContainer.removeChild(form)
            formContainer.appendChild(tyMessage)
        }
        return;
    }

    const sendEmail = (e) => {
        emailjs.sendForm("service_2fvno67", "template_6ayv9la", form.current, "QCrgyaMCRSucHgINe")
            .then((result) => {
                if (result.status === 200 && result.text === "OK") {
                    return removeForm();
                } else {
                    return setSentError("Currently the server is having issues with sending your message.");
                }
            }, (error) => {
                setSentError("Currently the server is having issues with sending your message.");
                return `error with message ${error}`
            });
    };

    useEffect(() => {

        if (message.length > 250) {
            setMessageError('Sorry your message is too long');
        } else {
            setMessageError('');
        }

        // email error check
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        if (!emailPattern.test(email)) {
            setEmailError('please enter a valid email address');
        } else {
            setEmailError('');
        }

        // name error check
        if (name.length <= 0) {
            setNameError('please enter your name');
        } else {
            setNameError('');
        }

    }, [email, message, name, emailError, messageError, nameError])


    const handleDropDownClick = (e) => {
        e.preventDefault()
        document.getElementById('dropdown_items').classList.toggle('hidden')
        return true
    }

    return (
        <div id="contact-form_container">
            <form
                ref={form}
                onSubmit={sendEmail}
                id="contact-form"
                method="post"
                action="#"
            >
                <div className="contact_form_fields">
                    <div className="field_half">
                        <input
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Name"
                            value={name}
                            onChange={handleNameChange}
                        />
                        <input
                            type="text"
                            name="email"
                            id="email"
                            placeholder="Email"
                            value={email}
                            onChange={handleEmailChange}
                        />
                    </div>
                    <div className="contact_form_dropdown">
                        <div id="dropdown_select_container">
                            <input id="selected_services" type="text" placeholder="Select services you are interested in (optional)" readOnly={true} />
                            <button onClick={handleDropDownClick} id="dropdown_button">&#x25BC;</button>
                        </div>
                        <div className="hidden" id="dropdown_items">
                            <div>
                                <label>
                                    <input name="checkbox_1" type="checkbox" value="Equity Plan Administration" />
                                    Equity Plan Administration
                                </label>
                                <label>
                                    <input name="checkbox_2" type="checkbox" value="Cap Table Reporting" />
                                    Cap Table Reporting
                                </label>
                                <label>
                                    <input name="checkbox_3" type="checkbox" value="Stock Based Compensation Expense" />
                                    Stock Based Compensation Expense
                                </label>
                                <label>
                                    <input name="checkbox_4" type="checkbox" value="Tax Reporting" />
                                    Tax Reporting
                                </label>
                                <label>
                                    <input name="checkbox_5" type="checkbox" value="Shareworks Implementation Assistance" />
                                    Shareworks Implementation Assistance
                                </label>
                                <label>
                                    <input name="checkbox_6" type="checkbox" value="Participant Communication" />
                                    Participant Communication
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="message_field">
                        <textarea
                            name="message"
                            placeholder="Message (optional)"
                            id="message"
                            rows="4"
                            value={message}
                            onChange={handleMessageChange}
                            maxLength="500"
                        />
                    </div>
                    {showError && <p className="error">{nameError}</p>}
                    {showError && <p className="error">{emailError}</p>}
                    {showError && <p className="error">{messageError}</p>}
                    {showError && <p className="error">{sentError}</p>}
                </div>
                <input
                    onClick={checkFormErrors}
                    type="submit"
                    value="Send Message"
                    id="submit_btn"
                />
            </form>
        </div>
    )
}


export default ContactForm
