import './WhoWeServe.css'




const WhoWeServe = () => {

    return (
        <div className="whoweserve-container">
            <div className="whoweserve">
                <div className="whoweserve-top">
                    <h1>Who We Serve</h1>
                </div>
                <div className="whoweserve-bottom">
                    <p>We work exclusively with private companies on the Shareworks equity management system or are in the process of implementing Shareworks. We firmly believe Shareworks is the most robust and flexible platform available. </p>
                </div>
            </div>
        </div>
    )
}


export default WhoWeServe
