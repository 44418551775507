import { useParams } from "react-router-dom"
import blogs from './blog/blog_manifest.json'
import './SingleBlogPage.css'




const SingeBlogPage = () => {

    const { idx } = useParams();

    const selectedBlog = blogs[idx];


    return(
        <div className="selected_blog_container">
            <div className="selected_blog_top">
                <h1>{selectedBlog.title}</h1>
                <span>{selectedBlog.date}</span>
                <div className="selected_blog_img_container">
                    <img src={require(`${selectedBlog.src}`)}/>
                </div>
            </div>
            <div className="selected_blog_bottom">
                <p>
                    {selectedBlog.body}
                </p>
            </div>
        </div>
    )
}



export default SingeBlogPage
