import { Link } from 'react-router-dom'
import './BlogCard.css'

const BlogCard = ({ idx, blogDetails }) => {


    return(
        // eslint-disable-next-line
        <Link
            to={`/blog/${idx}/${blogDetails.date.replace(/\//g, '')}/${blogDetails.title}`}
            className="BlogCard"
        >
            <div className="BlogCard_Top">
                <img src={require(`${blogDetails.src}`)} alt="placeholder"></img>
            </div>
            <div className="BlogCard_Bottom">
                <h3>{blogDetails.title}</h3>
                <span>{blogDetails.date}</span>
            </div>
        </Link>
    )
}

export default BlogCard
